import { checkAuth } from "hooks/checkAuth";
import { baseURL } from "utils/constants";
import { setErrorData } from "helpers/queryHelpers";

export async function getCategories() {
  await checkAuth();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
  };
  console.log(`get all categories`);
  const res = await fetch(`${baseURL}/categories`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
export async function createCategory(data) {
  await checkAuth();
  console.log("Order Query Data");
  console.log(data);

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  console.log(`Create New category`);

  const res = await fetch(`${baseURL}/categories`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function editCategory(data) {
  await checkAuth();
  console.log("Order Query Data");
  console.log(data);

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  console.log(`Edit category`);

  const res = await fetch(`${baseURL}/categories`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function deleteCategory(data) {
  await checkAuth();
  console.log("Delete category Data");
  console.log(data);

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({ categoryId: data }),
  };
  console.log(`Delete category`);

  const res = await fetch(`${baseURL}/categories`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
