import { checkAuth } from "hooks/checkAuth";
import { baseURL } from "utils/constants";
import { setErrorData } from "helpers/queryHelpers";
import { uploader } from "helpers/ImageHelper";

export async function getProducts() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
  };
  console.log(`get all products`);
  const res = await fetch(`${baseURL}/products`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
export async function getSingleProduct(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
  };
  console.log(`get single products`);
  const res = await fetch(
    `${baseURL}/products/single-product?id=${id}`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
export async function createProduct(data) {
  await checkAuth();
  console.log("Order Query Data");
  console.log(data);

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  console.log(`Create New product`);

  const res = await fetch(`${baseURL}/products`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function editProduct(data) {
  await checkAuth();
  console.log("Order Query Data");
  console.log(data);

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  console.log(`Edit product`);

  const res = await fetch(`${baseURL}/products`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function addOrEditProductImages({ data, processing, query }) {
  await checkAuth();
  console.log("Add and delete Product images");
  console.log("product images", data);
  if (query === "addImages") {
    const newImages = await uploader(processing.images);
    data.images = [...data.images, ...newImages];
  }
  console.log("Processed data", data);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  console.log("request options", requestOptions);
  console.log(`Add or edit product Images`);

  const res = await fetch(`${baseURL}/products/images`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function deleteProduct(data) {
  await checkAuth();
  console.log("Delete product Data");
  console.log(data);

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({ productId: data }),
  };
  console.log(`Delete product`);

  const res = await fetch(`${baseURL}/products`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
