const merchantDetails = [
  { id: "1", name: "Amazon", url: "https://www.amazon.in/" },
  { id: "2", name: "Flipkart", url: "https://www.flipkart.com/" },
  { id: "3", name: "Myntra", url: "https://www.myntra.com/" },
  { id: "4", name: "IndiaMart", url: "https://www.indiamart.com/" },
  { id: "5", name: "Shopclues", url: "https://bazaar.shopclues.com/?__ar=Y" },
  { id: "6", name: "Snapdeal", url: "https://www.snapdeal.com/" },
  { id: "7", name: "FirstCry", url: "https://www.firstcry.com/" },
  { id: "8", name: "Nykaa", url: "https://www.nykaa.com/" },
];

export { merchantDetails };
