import { successToast } from "helpers/Toast";
import { errorToast } from "helpers/Toast";
import { putChangePassword } from "queries/userQueries";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
const useChangePassword = () => {
  const [showPasswordDialog, setShowPasswordDialog] = React.useState(false);

  const methods = useForm();

  const openPasswordDetails = () => setShowPasswordDialog(true);
  const closePasswordDetails = () => {
    methods.reset();
    setShowPasswordDialog(false);
  };

  const editPassword = useMutation(putChangePassword, {
    onSuccess: (data) => {
      console.log(data);
      methods.reset();
      closePasswordDetails();
      successToast("Successfully changed Password");
    },
    onError: (error) => {
      console.log(error.message);
      errorToast(error.message);
    },
  });

  const onPasswordSubmit = (formData) => {
    console.log(formData);
    editPassword.mutate(formData);
  };
  return {
    showPasswordDialog,
    openPasswordDetails,
    closePasswordDetails,
    onPasswordSubmit,
    methods,
  };
};

export default useChangePassword;
