import { Input } from "components/Input";
import React from "react";
import ReactDom from "react-dom";

const PriceModal = ({
  productItem,
  register,
  errors,
  handleSubmit,
  onSubmit,
  onCancelHandler,
  orderType,
}) => {
  return ReactDom.createPortal(
    <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
      <div className="bg-white p-6">
        <h1 className="mb-8 ">Add Details</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          {(orderType == "cart" || orderType == "weBuy") && (
            <div className="flex flex-col ">
              <Input
                title="Procurable Quantity"
                inputName="procurableQuantity"
                inputType="number"
                defaultValue={productItem?.procurableQuantity}
                required
                register={register}
                errors={errors}
                max={productItem.quantity}
                maxMessage="The value cannot be greater than required quantity"
                min
              />
            </div>
          )}
          {productItem.itemType !== "buyYourself" && (
            <div className="flex flex-col ">
              <Input
                title="Price"
                inputName="price"
                inputType="number"
                defaultValue={productItem?.price}
                register={register}
                errors={errors}
                required
                min
              />
            </div>
          )}
          <div className="flex flex-col ">
            <Input
              title="Service Charges"
              inputName="serviceCharges"
              inputType="number"
              defaultValue={productItem?.serviceCharges}
              register={register}
              errors={errors}
              required
              min
            />
          </div>
          <div className="flex flex-col">
            <Input
              title="Tentative Date"
              inputName="tentativeDate"
              inputType="date"
              defaultValue={
                productItem.tentativeDate &&
                new Date(productItem?.tentativeDate)
                  .toISOString()
                  .substring(0, 10)
              }
              register={register}
              errors={errors}
              required
              min
            />
          </div>

          <div>
            <button
              className="bg-gray-500 px-4 py-2 rounded-md mr-6"
              onClick={onCancelHandler}
            >
              Cancel
            </button>
            <button className="bg-blue-500 px-4 py-2 rounded-md">Submit</button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default PriceModal;
