import decode from "jwt-decode";
// import useAuthStore from "stores/AuthStore";
import { refresh } from "queries/authQueries";
import { saveToken, logout } from "helpers/storageHelper";
const checkAuth = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const token = localStorage.getItem("accessToken");
  if (!token || !refreshToken) {
    return false;
  }
  try {
    const accessToken = token.split(" ")[1];
    const accTok = decode(accessToken);
    // const refTok = decode(refreshToken);
    if (
      accTok.exp <
      new Date().getTime() / 1000
      // &&
      // refTok.exp < new Date().getTime() / 1000
    ) {
      const res = await refresh({ refreshToken });
      console.log(res);
      if (!res.refreshToken) {
        logout();
        return false;
      }
      await saveToken(res.accessToken, res.refreshToken);

      return true;
    }
    return true;
  } catch (error) {
    logout();

    return false;
  }
};

export { checkAuth };
