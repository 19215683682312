import { baseURL } from "utils/constants";
import { setErrorData } from "helpers/queryHelpers";

export async function contactus(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const res = await fetch(`${baseURL}/contact-us`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
