import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const successToast = (message) => {
  toast.success(message);
};
const errorToast = (message) => {
  toast.error(message);
};
const ToastBox = () => {
  return <ToastContainer />;
};

export { successToast, errorToast, ToastBox };
