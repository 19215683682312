import ReactDom from "react-dom";
const DeleteModal = ({
  title,
  description,
  onCancel,
  onDelete,
  deleteText,
}) => {
  return ReactDom.createPortal(
    <div className="fixed w-screen h-screen top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
      <div className="bg-[#F0FEFC] p-6">
        <h1 className="mb-2 font-medium">{title}</h1>
        <p className="mb-8">{description}</p>
        <button
          className="bg-red-500 px-4 py-2 rounded-md mr-6"
          onClick={onDelete}
        >
          {deleteText || "Delete"}
        </button>
        <button className="bg-gray-500 px-4 py-2 rounded-md" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default DeleteModal;
